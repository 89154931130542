import { Link } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

export default function Navbar() {
  let navigation = useRef();

  const tl = gsap.timeline();

  useEffect(() => {
    tl.from(navigation, {
      y: -16,
      opacity: 0,
      duration: 1.4,
      ease: 'power3.inOut',
    });
  }, []);

  return (
    <nav
      ref={(el: any) => {
        navigation = el;
      }}>
      <img src='/Logo.png' alt='Logo' />
      <ul>
        <Link to='/'>HOME</Link>
        <Link to='/Projects/Project'>WORKS</Link>
        <Link to='/Blog/blogPost'>BLOGS</Link>
        <Link to='/Career/Career'>CAREERS</Link>
        <Link to='/About'>ABOUT</Link>
        <Link to='/Contact'><button>CONTACT US</button></Link>
      </ul>
    </nav>
  );
}
